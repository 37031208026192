import { encodeUrlParams } from "./url-params";
import { getRegionalizedUrl, TRACKING_API } from './url-utils';
import Axios from "axios";
import { getUser } from "@mc/common/lib/auth";

export const getAlertDetails = (cid, ep, dep) => {
  let paramOb = { v: 1 };
  if (dep) {
    paramOb['dep'] = dep;
  }
  else if (cid && ep) {
    paramOb['ep'] = ep;
    paramOb['cid'] = cid;
  }
  return Axios({
    method: 'get',
    url: getRegionalizedUrl(TRACKING_API) + '/codebreaker/details',
    params: paramOb,
    crossdomain: true,
  })
}

export const reportAlert = (email, report_category, description) => {
  console.log(email, report_category, description);
  const user = getUser();
  return Axios({
    method: 'post',
    url: getRegionalizedUrl(TRACKING_API) + '/codebreaker/report',
    data: encodeUrlParams({
      session: user.session,
      user_email: user.email,
      report_category,
      description,
      reportee_email: email.from_field,
      rcpt_to_field: email.rcpt_to_field,
      etorch_message_id: email.etorch_message_id,
      etorch_child_id: email.etorch_child_id,
    }),
    crossdomain: true,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    }
  })
}