
import React from "react"
import { niceList } from "@mc/common/lib/strings";
import { Row, Col } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { riskLevels } from './severity'

const ReportItem = (props) => {
  const title = props.title.replace('{recipients}', niceList(props.recipients))
  return (
    <Row style={props.lastItem ? { paddingBottom: 0 } : { paddingBottom: 5 }}>
      <Col span={24}>
        <FontAwesomeIcon
          style={{ paddingRight: 2 }}
          size='1x'
          icon={riskLevels[props.risk].faIcon} color={
            props.severityConfig ? props.severityConfig['primary']
              : riskLevels[props.risk].primaryColor
          } />
        {title}
      </Col>
    </Row >
  );
}

export default ReportItem
