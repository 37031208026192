export const niceList = (strArr) => {
    const arr = strArr.trim().replace(/\0/g, '').split(',').filter(Boolean);
    const num = arr.length;
    if (num > 3) {
        return `${arr[0]}, ${arr[1]}, and ${(num-2)} others`;
    } else if (num === 3) {
        return `${arr[0]}, ${arr[1]}, and ${arr[2]}`;
    } else if (num === 2) {
        return `${arr[0]} and ${arr[1]}`;
    }
    return arr[0];
}

export const formatArrayToString = (strArray) => {
    return strArray.join(", ");
}

export const validateEmail = (email) => {
    let re = /\S+@[\w\-]+\.[\w\-\.]+\w/
    return re.test(String(email).toLowerCase());
}

export const textToIDFormat = (text, suffix) => text ? text.toLowerCase().replace(/ /ig, '_') + (suffix ? '_' + textToIDFormat(suffix) : '') : '';

export const validateDomain = (domain) => {
    let re = /^[\w\-]+\.[\w\-\.]+\w/
    return re.test(domain)
}

export const extractIdToken = (queryString) => {
    var idToken = '';

    if (queryString) {
        var startIndex = queryString.indexOf('id_token');
        if (startIndex > -1) {
            var endIndex = queryString.indexOf('&', startIndex);
            if (endIndex === -1) {
                endIndex = queryString.length;
            }

            idToken = queryString.substring((startIndex + 9), endIndex);
        }
    }

    return idToken;
}
