const loadPendo = () =>{
    (function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];
          v=['initialize','identify','updateOptions','pageLoad'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
          y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
          z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
      })(process.env.GATSBY_PENDO_KEY);
}

/**
 * Verifies info needed for pendo initialization is valid.
 * @param {string} userId User identifier
 * @param {string} accountId Account identifier
 * @returns {boolean} true for valid, false otherwise
 */
const arePendoParamsValid = (userId, accountId) => {
    return userId && accountId;
}

export const initPendo = (userId='MC_PENDO_DEFAULT_USER', accountId='MC_PENDO_DEFAULT_ACCT' ) => {
    if(!window.pendo){
        loadPendo();
    }

    if(arePendoParamsValid(userId, accountId)) {
        if(window.pendo){
            window.pendo.initialize({
                visitor: {
                    id: userId
                },
                account: {
                    id: accountId  
                }
            });
        }
    } else {
        console.log("Invalid userId or accountId given");
    }
}