import React from "react"
import ReportItem from "./report-item";
import { Skeleton } from "antd";

const ReportItemList = (props) => {
  if (!props.ruleChecks) return (<>
    <Skeleton active title={false} />
  </>);
  if (props.ruleChecks.length === 0) return null;
  const listLength = props.ruleChecks.length
  return (
    <div style={{ marginTop: 0 }}>
      {props.ruleChecks.map((item, i) => {
        return (<ReportItem
          key={item.category}
          lastItem={listLength === i + 1}
          risk={item.severity}
          severityConfig={props.severityConfig ? props.severityConfig[item.severity] : null}
          title={item.message}
          recipients={props.email['rcpt_to_field']}
          description=""
          shrink={props.shrink} />)
      })}
    </div>
  );
}

export default ReportItemList
