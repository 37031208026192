import React from "react"
import Layout from "../components/layout"
import * as Sentry from "@sentry/browser";

import ReportItemList from "../components/report-item-list";
import FeedbackSection from "@mc/common/components/feedback";
import styles from './alert-details.module.css';
import { getAlertUrlParams } from "@mc/common/lib/url-params";
import { getAlertDetails, reportAlert } from "@mc/common/lib/alerts";
import { StateContext } from '../state/store';
import { navigate } from "gatsby";
import { validateSession, setUser, setRedirectPath, getSSORedirectUrl, getUser } from "@mc/common/lib/auth";
import { Row, Col, Button, Card } from "antd";
import Confetti from 'react-dom-confetti';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faBan, faCheckCircle, faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import { faUserCircle, faCheckCircle as faSolidCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { INFO, riskLevels } from "../components/severity";
import { initPendo } from "@mc/common/lib/pendo";

const confettiConfig = {
    angle: "0",
    spread: "360",
    startVelocity: "14",
    elementCount: "100",
    dragFriction: "0.05",
    duration: "3000",
    stagger: "0",
    width: "10px",
    height: "10px",
    colors: ['#1976D2', '#FF6659', '#FFD149']
};

class AlertDetailsPage extends React.Component {
    static contextType = StateContext;

    state = {
        category: null,
        description: '',
        submitting: false,
        submitted: false,
        success: false,
        showMoreDetail: false,
        notFoundMode: false,
        errorCode: null,
        errorMessage: null,
        errorSubText: null,
    }

    componentDidMount() {
        const [{ email }, dispatch] = this.context;

        try {
            if (window.location.href && window.location.href.indexOf("https://report.messagecontrol.com/") >= 0) {
                let queryParams = this.props.location.search;
                if (queryParams) {
                    queryParams = (queryParams.indexOf("?") >= 0 ? queryParams : "?" + queryParams);
                    window.location = "https://us.report.cybergraph.mimecast.com/alert-details/" + queryParams;
                    return;
                }
            }
        } catch (e) {}

        // Short circuit scenario - we know the email this user wants to look at
        if (email) {
            let user = getUser();
            initPendo(user.email, email.customer_info.global_customer_id );
            return;
        }
        const params = getAlertUrlParams(this.props.location);

        if (!params['dep'] && (!params['cid'] || !params['ep'])) {
            this.setState({
                notFoundMode: true,
            });
            dispatch({
                type: 'setLoading',
                loading: false,
            });
        }

        getSSORedirectUrl(params['cid'], params['dep']).then((response) => {
            if (response) {
                const existingUser = getUser();
                if (existingUser.isLoggingInSSO !== true) {
                    setRedirectPath();
                    window.location = response;
                    return;
                }
                else {
                    existingUser.isLoggingInSSO = false;
                    setUser(existingUser);
                }
            }

            validateSession().then((response) => {
                dispatch({
                    type: 'setLoading',
                    loading: true,
                });
                getAlertDetails(params['cid'], params['ep'], params['dep']).then((response) => {
                    if (!response.data.rule_checks) {
                        try{
                            Sentry.captureMessage('getAlertDetails came back empty/invalid', Sentry.Severity.Critical);
                            this.setState({
                                notFoundMode: true,
                                errorCode: 404,
                                errorMessage: 'Alert details not found.',
                                errorSubText: 'We had trouble finding the email you requested to report.',
                            });
                            dispatch({
                                type: 'setLoading',
                                loading: false,
                            });    
                        }
                        catch(e){
                            console.log(e);
                        }
                        return;
                    }

                    // Sometimes the ruleChecks are keyed based on category.
                    // Fixed on the API but keeping as a fallback.
                    const ruleChecks = Object.values(response.data.rule_checks).sort(
                        (a, b) => b.severity - a.severity);

                    let user = getUser();
                    if(response.data.customer_info.global_customer_id) {
                        initPendo(user.email, response.data.customer_info.global_customer_id);
                    } else {
                        console.log('Missing user/customer information for initializing metrics')
                    }

                    dispatch({
                        type: 'setEmail',
                        email: response.data.email_details,
                        customer_info: response.data.customer_info,
                        ruleChecks: ruleChecks,
                        highestSeverity: ruleChecks.length ? ruleChecks[0].severity : INFO,
                        severityConfig: response.data.severity_config,
                    });

                    if (params['action'] === 'safe') {
                        this.setState({ category: 'safe' })
                        this.onSubmit('safe')
                    }
                }).catch((error) => {
                    console.log(error);
                    Sentry.captureException(error);
                    if (error && error.response && error.response.status &&
                        (error.response.status === 400 || error.response.status === 404)) {
                        this.setState({
                            notFoundMode: true,
                            errorCode: error.response.status,
                            errorMessage: 'Alert details not found.',
                            errorSubText: 'We had trouble finding the email you requested to report.',
                        });
                        dispatch({
                            type: 'setLoading',
                            loading: false,
                        });
                    } else {
                        this.setState({
                            notFoundMode: true,
                            errorMessage: 'Something went wrong',
                            errorSubText: 'We couldn’t process the request.',
                        });
                        dispatch({
                            type: 'setLoading',
                            loading: false,
                        });

                    }
                });
            }).catch((error) => {
                console.log(error.response);
                setUser({
                    loggedIn: false
                });

                navigate('/login' + window.location.search);
            });
        });
    }

    onSubmit = () => {
        const [{ email },] = this.context;
        this.setState({ submitting: true });
        reportAlert(email, this.state.category, this.state.description).then(() => {
            this.setState({
                submitting: false,
                submitted: true,
            });

            setTimeout(() => {
                this.setState({ success: true });
            }, 300);
        }).catch(() => {
            this.setState({ submitting: false });
        });
    }

    onSelectCategory = (warn_obj) => {
        this.setState({
            category: warn_obj.category,
        })
    }

    onChangeDescription = (e) => {
        this.setState({
            description: e.target.value,
        })
    }

    onToggleShowMore = () => {
        this.setState({
            showMoreDetail: !this.state.showMoreDetail,
        })
    }

    renderFeedbackForm = () => {
        const [{ email, ruleChecks, severityConfig, highestSeverity }] = this.context;

        const warning_obs = [
            {
                category: 'dangerous',
                id: 'report-category-dangerous-button',
                icon: faExclamationCircle,
                iconColor: '#D32F2F',
                bodyText: 'Report as dangerous to my security team'
            },
            {
                category: 'spam',
                id: 'report-category-spam-button',
                icon: faBan,
                iconColor: '#333333',
                bodyText: 'Report as spam to my security team'
            },
            {
                category: 'safe',
                id: 'report-category-safe-button',
                icon: faCheckCircle,
                iconColor: '#388E3C',
                bodyText: 'Mark as safe and hide warnings'
            },
        ]

        return (
            <Card title={
                <div style={{ whiteSpace: 'normal' }}>
                    {
                        (severityConfig && severityConfig[highestSeverity]
                            && severityConfig[highestSeverity].severityMessage)
                            ? severityConfig[highestSeverity].severityMessage
                            : riskLevels[highestSeverity].severityMessage}
                </div>
            }
                className={styles.alertContentCard}
                headStyle={{
                    fontFamily: "Open Sans",
                    fontSize: '24px',
                    fontWeight: 600,
                    color: '#333333',
                    letterSpacing: '0',
                    lineHeight: '28px',
                    width: '100%',
                    borderBottom: 0,
                }}
                bodyStyle={{ paddingTop: '0px', color: '#333333', }}>
                < Row>
                    <Col xs={24} sm={2} >
                        <FontAwesomeIcon size='3x' icon={faUserCircle} color='#999999' style={{ alignSelf: 'center' }} />
                    </Col>
                    <Col xs={24} sm={22} style={{ paddingLeft: '10px', overflowWrap: 'break-word' }}>
                        <Row style={{ fontWeight: 600 }}> {email
                            ? email['from_field_display_name'] + ' <' + email['sender_field'] + '>'
                            : <div style={{ display: 'inline-block', backgroundColor: '#eee', width: '100%', height: 14 }} />}
                        </Row>
                        <Row >
                            <Col xs={24} sm={3} style={{ fontWeight: 600, color: '#666666' }}>
                                {email ? "Subject:"
                                    : <div style={{ display: 'inline-block', backgroundColor: '#eee', width: '100%', height: 14 }} />}
                            </Col>

                            <Col xs={24} sm={21} style={{ paddingLeft: 5, overflowWrap: 'break-word' }}>
                                {email ? email['subject_field']
                                    : <div style={{ display: 'inline-block', backgroundColor: '#eee', width: '100%', height: 14 }} />}
                            </Col>
                        </Row>
                        <Row hidden={!this.state.showMoreDetail}>
                            <Col xs={24} sm={3} style={{ fontWeight: 600, color: '#666666' }}>
                                To:
                            </Col>
                            <Col xs={24} sm={21} style={{ paddingLeft: 5, overflowWrap: 'break-word' }}>
                                {email ? email['rcpt_to_field']
                                    : <div style={{ display: 'inline-block', backgroundColor: '#eee', width: '100%', height: 14 }} />}
                            </Col>
                        </Row>
                        {email &&
                            <Row>
                                {this.state.showMoreDetail ?
                                    <Button id='show-less-detail-button' style={{ paddingLeft: 0, paddingBottom: 10, height: 'min-content' }} type="link" onClick={this.onToggleShowMore}>
                                        Show Less
                                <FontAwesomeIcon style={{ paddingLeft: 3, }} size='1x' icon={faChevronUp} />
                                    </Button> :
                                    <Button id='show-more-detail-button' style={{ paddingLeft: 0, paddingBottom: 10, height: 'min-content' }} type="link" onClick={this.onToggleShowMore}>
                                        Show More
                                <FontAwesomeIcon size='1x' style={{ paddingLeft: 3 }} icon={faChevronDown} />
                                    </Button>
                                }
                            </Row>
                        }
                        <ReportItemList ruleChecks={ruleChecks} email={email} severityConfig={severityConfig} shrink />
                    </Col>
                </Row >
                {email && <Row style={{
                    fontSize: 20,
                    fontWeight: 600,
                    paddingTop: 30,
                    paddingBottom: 20,
                    paddingLeft: 10,
                    lineHeight: '24px',
                }}>
                    What do you want to do with this message?
                </Row>
                }
                {email && <Row gutter={[12, 20]} justify="space-between" style={{ maxWidth: '600px', boxSizing: 'border-box', margin: 0 }}>
                    {warning_obs.map((warning_ob) => {
                        const selected = this.state.category === warning_ob.category;
                        return (
                            <Col key={warning_ob.category} xs={24} sm={24} md={8} lg={8} xl={8}
                                style={{}}>
                                <div
                                    id={warning_ob.id}
                                    style={selected ? { border: '2px solid #1976D2', background: 'rgba(25, 118, 210, 0.1)', padding: '8px' } : {}}
                                    className={styles.rectangleRadioButtons}
                                    value={warning_ob.category} onClick={() => this.onSelectCategory(warning_ob)} >
                                    <div className={styles.rectangleRadioButtonsIcon}>
                                        <FontAwesomeIcon size='2x' icon={warning_ob.icon} color={warning_ob.iconColor} />
                                    </div>
                                    <span className={styles.rectangleRadioButtonText}>
                                        {warning_ob.bodyText}
                                    </span>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
                }
                <Button
                    id='report-form-submit-button'
                    disabled={!this.state.category}
                    style={!this.state.category ? {
                        opacity: '65%', backgroundColor: '#1976D2',
                        color: '#FFFFFF',
                    }
                        : {}}
                    className={styles.submitButton}
                    onClick={() => this.onSubmit()}>
                    Submit
                </Button>
            </Card >
        )
    }

    renderComplete = () => {
        const [{ email }] = this.context;
        let subtitle = 'You won’t get warnings for this sender in future.';
        if (this.state.category === 'dangerous' || this.state.category === 'spam') {
            subtitle = 'You can now move the message to your junk folder. We’ll keep sending warnings for this sender.';
        }
        return (
            <Card
                className={styles.alertContentCard}
                headStyle={{
                    fontFamily: "Open Sans",
                    fontSize: '24px',
                    fontWeight: 600,
                    color: '#333333',
                    letterSpacing: '0',
                    lineHeight: '28px',
                    width: '100%',
                    borderBottom: 0,
                }}
                bodyStyle={{ paddingTop: '0px', color: '#333333', }}>
                <div style={{ textAlign: 'center', margin: '20px 0px' }}>
                    <FontAwesomeIcon icon={faSolidCheckCircle} size='4x' color='#388E3C' />
                    <div style={{ margin: '0 auto', width: 10 }}>
                        <Confetti active={this.state.success} config={confettiConfig} />
                    </div>
                </div>
                <div style={{ textAlign: 'left', fontFamily: 'Open Sans', }}>
                    <div style={{ fontSize: 24, fontWeight: 600, marginBottom: 30 }}>You marked this message as {this.state.category}</div>
                    <div style={{
                        fontSize: 16,
                        fontWeight: 600
                    }}>Your feedback helps make email safer for everyone!</div>
                    <div style={{
                        fontSize: 14,
                    }}>
                        {subtitle}
                    </div>
                </div>
                { email && <FeedbackSection eId={email.etorch_message_id} context="codebreaker" showFeedback="true" />}
            </Card >
        )
    }

    renderError() {
        return (<Card
            title={
                <>
                    <div>
                        {this.state.errorCode ? 'Error ' + this.state.errorCode + ':' : 'Error:'}
                    </div>
                    <div>
                        {true && this.state.errorMessage}
                    </div>
                    <div style={{ fontSize: 16, paddingTop: 18 }}>
                        {true && this.state.errorSubText}
                    </div>
                </>
            }
            className={styles.alertContentCard}
            headStyle={{
                fontFamily: "Open Sans",
                fontSize: '24px',
                fontWeight: 600,
                color: '#333333',
                letterSpacing: '0',
                lineHeight: '28px',
                width: '100%',
                borderBottom: 0,
            }}
        >
        </Card>
        );
    }

    render() {
        return (
            <Layout>
                <div style={{ maxWidth: 1200, width: 'auto', margin: 'auto' }}>
                    {this.state.notFoundMode ? this.renderError() : (
                        this.state.submitted ?
                            this.renderComplete() :
                            this.renderFeedbackForm())
                    }
                </div>
            </Layout >
        );
    }
}

export default AlertDetailsPage
